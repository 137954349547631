import React from "react";
import { graphql } from "gatsby";
import Layout from "../../../components/Layout";
import Seo from "../../../components/Seo";
import { HTMLContent } from "../../../components/Content";
import Article from "./Article";

const Template = ({ data, location }) => {
  const { article, prevArticle, nextArticle } = data;
  const {
    html,
    excerpt,
    frontmatter: { title, releasedAt },
  } = article;

  return (
    <Layout>
      <Seo
        title={`${title} | Neuigkeiten`}
        pathname={location.pathname}
        article={true}
        description={`${title}. ${excerpt}`}
      />
      <Article
        content={html}
        contentComponent={HTMLContent}
        title={title}
        releasedAt={releasedAt}
        location={location}
        prev={prevArticle}
        next={nextArticle}
      />
    </Layout>
  );
};

export default Template;

export const query = graphql`
  query ArticlePage($id: String!, $prevId: String, $nextId: String) {
    article: markdownRemark(id: { eq: $id }) {
      html
      excerpt(pruneLength: 100)
      frontmatter {
        title
        releasedAt
      }
    }
    prevArticle: markdownRemark(id: { eq: $prevId }) {
      frontmatter {
        title
        releasedAt
      }
      fields {
        slug
      }
    }
    nextArticle: markdownRemark(id: { eq: $nextId }) {
      frontmatter {
        title
        releasedAt
      }
      fields {
        slug
      }
    }
  }
`;
