/** @jsxImportSource theme-ui */
import React from "react";
import Content from "../../../components/Content";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { PrevPage, NextPage } from "../../../components/PrevNextPage";
import { formatLocalDate } from "../../../utils/dateTime";
import { BaseStyles, Container } from "theme-ui";

const Article = ({
  content,
  contentComponent,
  title,
  releasedAt,
  prev,
  next,
  location,
}) => {
  const ContentRenderer = contentComponent || Content;
  let numberOfRefs = [prev, next].filter((x) => x != null).length;

  return (
    <BaseStyles>
      <Breadcrumbs names={["Neuigkeiten", title]} location={location} />
      <div sx={{ variant: "layout.grid.prevNext", "--numRows": numberOfRefs }}>
        {prev && (
          <div sx={{ variant: "layout.grid.prev" }}>
            <PrevPage link={prev.fields.slug}>
              <span sx={{ variant: "prevNext.meta" }}>vorheriger Artikel:</span>
              <div sx={{ variant: "prevNext.text" }}>
                {prev.frontmatter.title}{" "}
                <span sx={{ variant: "prevNext.date" }}>
                  ({formatLocalDate(prev.frontmatter.releasedAt)})
                </span>
              </div>
            </PrevPage>
          </div>
        )}
        <div sx={{ variant: "layout.grid.main" }}>
          <Container>
            <article>
              <header>
                <h2 className="mb-0">{title}</h2>
              </header>
              <main className="mt-3">
                <ContentRenderer content={content} />
              </main>
              <footer sx={{ variant: "cards.footer" }}>
                <span>
                  veröffentlicht am{" "}
                  <time dateTime={releasedAt}>
                    {formatLocalDate(releasedAt)}
                  </time>
                </span>
              </footer>
            </article>
          </Container>
        </div>
        {next && (
          <div sx={{ variant: "layout.grid.next" }}>
            <NextPage link={next.fields.slug}>
              <span sx={{ variant: "prevNext.meta" }}>nächster Artikel:</span>
              <div sx={{ variant: "prevNext.text" }}>
                {next.frontmatter.title}{" "}
                <span sx={{ variant: "prevNext.date" }}>
                  ({formatLocalDate(next.frontmatter.releasedAt)})
                </span>
              </div>
            </NextPage>
          </div>
        )}
      </div>
    </BaseStyles>
  );
};

export default Article;
